<template>
  <li
    @click="isTagging ? $emit('tag', item) : null"
    :class="{ tagged: active, tagging: isTagging, selected: selectedItem && selectedItem.id === item.id }"
  >
    <div class="checklist-header">
      <button
        @click="isTagging ? $emit('tag', item) : toggleActive()"
        class="checklist-button"
      >
        <i
          class="checklist-icon add-icon"
          :class="[`highlight-${item.color}`, `${active ? 'tagged' : 'tagging'}`]"
          v-if="isTagging"
        ></i>
        <i
          class="checklist-icon"
          v-if="!isTagging"
          :class="[`highlight-${item.color}`, `${active ? 'tagged' : 'not-tagged'}`]"
        ></i>
        <span class="button-link">
          <span v-if="isTagging">Tag</span>
          {{ item.title }}
        </span>
        <span v-if="item.required" class="required" aria-label="(required)">*</span>
      </button>
      <div class="checklist-actions">
        <button
          v-if="loggedIn && active && showCross && !readOnly"
          class="highlight-icon button-icon"
          @click.stop="toggleActive()"
          :aria-label="`Highlight ${ item.title } Tag`"
        >
        </button>
        <button
          v-if="loggedIn && active && showCross && !readOnly"
          class="remove-icon button-icon"
          @click.stop="$emit('remove', item)"
          :aria-label="`Remove ${ item.title } Tag`"
        >
        </button>
      </div>
    </div>
    <div :class="[`${itemInstances.length && !readOnly ? 'checklist-details-instances' : 'checklist-details'}`, `border-${item.color}`]">
      <p @click="isTagging ? $emit('tag', item) : toggleActive()" class="checklist-description">
        {{ item.description }}
      </p>
      <div
        class="tagged-instances"
        v-show="itemInstances.length && !readOnly"
      >
        <button
          class="tagged-instances-toggle"
          @click="toggleInstances"
        >
          <span>
            <span v-if="hideInstances">Show</span>
            <span v-else>Hide</span>
            {{itemInstances.length}} Tagged Instance<span v-if="hasMultipleInstances">s</span>
          </span>
          <div
            class="toggle-icon button-icon"
            :class="{ 'closed-icon': hideInstances }"
          >
          </div>
        </button>
        <ul
          class="no-list-styling tagged-instances-list"
          :class="{ 'closed-instances': hideInstances }"
        >
          <li
            v-for="(tag, index) in itemInstances"
            :key="tag.id"
          >
            <div class="instance-header">
              <span>Instance {{index + 1}}</span>
              <div class="instance-actions">
                <!-- TODO: Add and addressed highlighting of individual instance in future cycle
                <button
                  v-if="loggedIn && active && showCross && !readOnly"
                  class="highlight-icon button-icon"
                  @click.stop="toggleInstance(tag)"
                  aria-label="Highlight Tag"
                >
                </button>
                -->
                <button
                  v-if="loggedIn && active && showCross && !readOnly"
                  class="remove-icon button-icon"
                  @click.stop="removeInstance(tag)"
                  aria-label="Remove Tag"
                >
                </button>
              </div>
            </div>
            <p class="instance-preview">"{{ tag.content }}"</p>
          </li>
        </ul>
      </div>
    </div>
  </li>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },

    item: {
      type: Object,
      required: true,
    },

    readOnly: {
      type: Boolean,
      default: false,
    },

    selectedItem: {
      type: Object,
      default: null,
    },

    loggedIn: {
      type: Boolean,
      default: false,
    },

    showCross: {
      type: Boolean,
    },

    isTagging: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showRemove: false,
      hideInstances: true,
    };
  },

  computed: {
    itemInstances() {
      return this.currentDraftTags.filter(tag => tag.template_checklist_item_id == this.item.id);
    },

    hasMultipleInstances() {
      return this.itemInstances.length > 1;
    },

    ...mapState("drafting", { currentDraftTags: "currentDraftTags" }),
  },

  methods: {
    ...mapActions("drafting", {
      removeTag: "removeTag",
    }),

    toggleActive() {
      if (this.selectedItem && this.selectedItem.id === this.item.id) {
        this.$emit("deselect", this.item);
      } else if (!this.isTagging) {
        this.$emit("select", this.item);
      }
    },

    toggleInstance(tag) {
      if (this.selectedItem && this.selectedItem.template_checklist_item_id === tag.template_checklist_item_id) {
        this.$emit("deselect", tag);
      } else if (!this.isTagging) {
        this.$emit("select", tag);
      }
    },

    removeInstance(tag) {
      this.removeTag(tag.id);
    },

    toggleInstances () {
      this.hideInstances = !this.hideInstances;
    },
  },
};
</script>
