import axios from "axios";
import AuthService from "./auth";

const baseURL = "/api/templates/";

export default {
  all() {
    return axios.get(baseURL);
  },

  show(id, isGuest = false) {
    const axios = AuthService.createCustomHeaderAxios(isGuest);
    return axios.get(`${baseURL}${id}/`);
  },

  create(data) {
    return axios.post(`${baseURL}`, data);
  },

  update(id, data) {
    return axios.put(`${baseURL}${id}/`, data);
  },

  destroy(id) {
    return axios.delete(`${baseURL}${id}/`);
  },

  listMembers(id, meOnly = false) {
    let url = `${baseURL}${id}/members/`;
    if (meOnly) {
      url = `${url}?self_only=true`;
    }
    return axios.get(url);
  },

  addMember(id, data) {
    return axios.post(`${baseURL}${id}/members/`, data);
  },

  updateMember(id, memberId, data) {
    return axios.put(`${baseURL}${id}/members/${memberId}/`, data);
  },

  destroyMember(id, memberId) {
    return axios.delete(`${baseURL}${id}/members/${memberId}/`);
  },

  join(code) {
    return axios.post(`/api/join-template/`, { code });
  },

  clone(id) {
    return axios.post(`/api/clone-template/${id}/`);
  },

  bulkUserUpload(id, data) {
    return axios.post(`${baseURL}${id}/members/bulk-upload/`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  listCanvasAssociations(id) {
    return axios.get(`${baseURL}${id}/canvas-associations/`);
  },
  addCanvasAssociation(id, data) {
    return axios.post(`${baseURL}${id}/canvas-associations/`, data);
  },
  updateCanvasAssociation(id, associationId, data) {
    return axios.put(`${baseURL}${id}/canvas-associations/${associationId}/`, data);
  },
  destroyCanvasAssociation(id, associationId) {
    return axios.delete(`${baseURL}${id}/canvas-associations/${associationId}/`);
  },
  importStudentsFromCanvasAssociation(id, associationId) {
    return axios.post(`${baseURL}${id}/canvas-associations/${associationId}/import-students/`);
  },

  export(id, token) {
    return axios.get(`${baseURL}${id}/export?token=${token}`);
  },

  downloadReport() {
    return axios.get(`/api/download-report`);
  }
};
