<template>
  <div class="container external-page layout-not-logged-in">
    <et-header class="external"></et-header>
    <main id="main" class="container">
      <div class="narrow-container">
        <template v-if="!success">
          <h1 class="serif text-large text-highlight-primary">Let's reset your password.</h1>
          <input
            class="input text-small"
            placeholder="Email address"
            v-model="resetForm.email"
            @input="resetError"
          />
          <p
            v-if="$v.resetForm.email.$dirty && !$v.resetForm.email.email"
            class="error-text-lighter text-small"
            aria-live="polite"
          >
            Please enter valid email
          </p>
          <p
            v-if="$v.resetForm.email.$dirty && !$v.resetForm.email.required"
            class="error-text-lighter text-small"
            aria-live="polite"
          >
            Email is required
          </p>
          <p v-if="error" class="error-text-lighter text-small" aria-live="polite">
            {{ error }}
          </p>
          <button class="button" :disabled="$v.$error" @click="submitReset">Send Email</button>
        </template>
        <template v-else>
          <h1 class="serif text-large text-highlight-primary">
            We sent you an email to reset your password!
          </h1>
          <p class="text-small text-center">
            If you did not receive the email check your spam folder or if you're still having
            trouble contact:
            <a href="mailto:lettersmith-support@umich.edu">lettersmith-support@umich.edu</a>
          </p>
          <br />
          <p class="text-small text-center">You can now close this browser window.</p>
        </template>
      </div>
    </main>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import AuthService from "services/auth";

export default {
  name: "LoginForgotPassword",

  data: () => ({
    error: null,
    success: false,
    resetForm: {
      email: null,
    },
  }),

  methods: {
    submitReset() {
      this.$v.$touch();
      if (this.$v.$error) {
        return;
      }
      AuthService.forgotPassword({ email: this.resetForm.email })
        .then(() => {
          this.success = true;
        })
        .catch((error) => {
          const emailError = error?.response?.data?.email;
          if (emailError) {
            if (typeof emailError === "object") {
              this.error = error.response.data.email[0];
            } else if (typeof emailError === "string") {
              this.error = emailError;
            }
          }
        });
    },

    resetError() {
      this.error = null;
    },
  },

  validations: {
    resetForm: {
      email: {
        required,
        email,
      },
    },
  },
};
</script>

<style scoped></style>
