<template>
  <div class="video-container">
    <video ref="video" class="feature-video" autoplay loop muted>
      <source :src="featureInteraction" type="video/mp4" />
    </video>
    <div class="video-control">
      <button v-if="videoPaused" @click="play" class="button">
        <i>Play Video</i>
      </button>
      <button v-else @click="pause" class="button">
        <i>Pause Video</i>
      </button>
      <button @click="restart" class="button">
        <i>Restart Video</i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    featureInteraction: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    videoPaused: false,
  }),

  methods: {
    pause() {
      this.$refs.video.pause();
      return this.videoPaused = true;
    },
    play() {
      this.$refs.video.play();
      return this.videoPaused = false;
    },
    restart() {
      this.$refs.video.currentTime = 0;
      this.$refs.video.play();
      return this.videoPaused = false;
    }
  },
};
</script>