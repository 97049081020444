<template>
  <main id="main" class="container-min flex column admin-wrapper">
    <div class="breadcrumb-navigation">
      <router-link class="button-link" :to="{ name: 'Dashboard' }">Dashboard</router-link>
      <img alt="" src="/static/images/icons/arrow-dark-blue.png" />
      <router-link class="button-link" :to="{ name: 'AdminHome' }">Site Administration</router-link>
      <img alt="" src="/static/images/icons/arrow-dark-blue.png" />
      <p class="current-location">Edit LTI Registration</p>
    </div>
    <div class="admin-header">
      <h1 class="title serif">Edit LTI Registration</h1>
    </div>
    <div class="admin-content">
      <div class="admin-item details-container">
        <header>
          <div class="header-container">
            <img alt="" src="/static/images/icons/lti-registration.svg" />
            <h2 class="bold text-med">Edit LTI Registration</h2>
          </div>
        </header>
        <div class="card-container not-table">
          <form>
            <div class="form-field">
              <label class="form-label bold" for="name">Name:</label>
              <input
                class="input"
                id="name"
                required
                v-model="name"
                v-bind="errorAttributes('name')"
              />
              <p class="error-text" id="name_error" v-if="errors.name" aria-live="polite">{{ errors.name}}</p>
            </div>

            <div class="form-field">
              <label class="form-label bold" for="issuer">Issuer:</label>
              <input
                class="input"
                id="issuer"
                required
                v-model="issuer"
                v-bind="errorAttributes('issuer')"
              />
              <p class="error-text" id="issuer_error" v-if="errors.issuer" aria-live="polite">{{ errors.issuer}}</p>
            </div>

            <div class="form-field">
              <label class="form-label bold" for="client_id">Client ID:</label>
              <input
                class="input"
                id="client_id"
                required
                v-model="cliendId"
                v-bind="errorAttributes('client_id')"
              />
              <p class="error-text" id="client_id_error" v-if="errors.client_id" aria-live="polite">{{ errors.client_id}}</p>
            </div>

            <div class="form-field">
              <label class="form-label bold" for="auth_url">Auth URL:</label>
              <input
                class="input"
                id="auth_url"
                required
                v-model="authUrl"
                v-bind="errorAttributes('auth_url')"
              />
              <p class="error-text" id="auth_url_error" v-if="errors.auth_url" aria-live="polite">{{ errors.auth_url}}</p>
            </div>

            <div class="form-field">
              <label class="form-label bold" for="token_url">Token URL:</label>
              <input
                class="input"
                id="token_url"
                required
                v-model="tokenUrl"
                v-bind="errorAttributes('token_url')"
              />
              <p class="error-text" id="token_url_error" v-if="errors.token_url" aria-live="polite">{{ errors.token_url}}</p>
            </div>

            <div class="form-field">
              <label class="form-label bold" for="keyset_url">Keyset URL:</label>
              <input
                class="input"
                id="keyset_url"
                required
                v-model="keysetUrl"
                v-bind="errorAttributes('keyset_url')"
              />
              <p class="error-text" id="keyset_url_error" v-if="errors.keyset_url" aria-live="polite">{{ errors.keyset_url}}</p>
            </div>

            <div class="form-field">
              <label class="active-checkbox">
                <input id="checkbox" type="checkbox" v-model="isActive" />
                Is Active
              </label>
            </div>

            <div class="buttons">
              <button class="button" type="button" @click="updateRegistration">
                Save Registration
              </button>
              <router-link class="button-secondary" type="button" :to="{ name: 'AdminHome' }">
                Cancel
              </router-link>
            </div>
          </form>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { defineComponent, ref, reactive } from "vue";
import { useRoute, useRouter, useStore } from "shared/composables";

export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const getRegistrationById = store.getters["admin/getRegistrationById"];
    const id = parseInt(route.params.registrationId);
    const registration = getRegistrationById(id);

    const name = ref(registration.name);
    const issuer = ref(registration.issuer);
    const cliendId = ref(registration.client_id);
    const authUrl = ref(registration.auth_url);
    const tokenUrl = ref(registration.token_url);
    const keysetUrl = ref(registration.keyset_url);
    const isActive = ref(registration.is_active);

    let errors = reactive({
      name: "",
      issuer: "",
      client_id: "",
      auth_url: "",
      token_url: "",
      keyset_url: "",
      is_active: "",
    });

    const updateRegistration = async () => {
      const registration = {
        name: name.value,
        issuer: issuer.value,
        client_id: cliendId.value,
        auth_url: authUrl.value,
        token_url: tokenUrl.value,
        keyset_url: keysetUrl.value,
        is_active: isActive.value,
      };
      try {
        await store.dispatch("admin/updateRegistration", { id, registration});
        store.dispatch("showSnackbar", `${registration.name} has been updated.`);
        router.push({ name: "AdminHome" });
      } catch (error) {
        for (const key in errors) {
          errors[key] = error.data[key] ? error.data[key].join(" ") : "";
        }
        store.dispatch("showSnackbar", "Please correct your errors.");
      }
    }

    const errorAttributes = (key) => {
      if (!errors[key]) {
        return {};
      }
      return {
        "aria-invalid": "true",
        "aria-describedby": key + "_error",
      };
    }

    return {
      name,
      issuer,
      cliendId,
      authUrl,
      tokenUrl,
      keysetUrl,
      isActive,
      errors,
      errorAttributes,
      updateRegistration,
    };
  },
});
</script>
