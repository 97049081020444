<template>
  <div class="container external-page layout-not-logged-in">
    <et-header class="external"></et-header>
    <main id="main" class="container">
      <div class="narrow-container">
        <h1 class="serif text-large text-highlight-primary">Sign up for a free account.</h1>
        <form @submit.prevent="submitSignup" class="text-center">
          <label for="name" class="visually-hidden">Name</label>
          <input
            id="name"
            class="input"
            autocomplete="name"
            placeholder="Full name"
            v-model="signupForm.name"
          />
          <label for="email" class="visually-hidden">Email</label>
          <input
            id="email"
            class="input"
            autocomplete="email"
            placeholder="Email address"
            v-model="signupForm.email"
          />
          <input
            v-if="signupForm.inviteToken"
            type="password"
            autocomplete="current-password"
            class="input"
            placeholder="Password"
            v-model="signupForm.password"
          />
          <button
            type="submit"
            class="button text-small"
            :disabled="
              !signupForm.name ||
              !signupForm.email ||
              (signupForm.inviteToken && !signupForm.password)
            "
          >
            Create account
          </button>
          <p v-if="errorMessage" class="error-text-lighter text-small" aria-live="polite">{{ errorMessage }}</p>
        </form>
        <div class="separator text-small">or</div>
        <g-signin-button
          :params="googleSignInParams"
          @success="googleSigninSuccess"
          @error="googleSigninError"
        >
          <button class="button-secondary text-small"><i class="fab fa-google"></i> Sign up with Google</button>
        </g-signin-button>
        <a class="button-secondary text-small um-login-btn" :href="umLoginUrl">
          <img
            class="um-logo"
            alt="university of michigan block m logo"
            src="/static/images/logos/um-mark.png"
          />
          Login with U-M
        </a>

        <router-link
          v-if="!templateId"
          :to="{ name: 'Login', query: $route.query }"
          class="button-link text-small blue-dark"
        >
          Login with existing Lettersmith account
        </router-link>
        <router-link
          v-else
          :to="{ name: 'LoginWithTemplate', params: { templateId } }"
          class="light-grey small-text"
        >
          Login here
        </router-link>
      </div>
    </main>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import { mapActions, mapState } from "vuex";
import router from "~/router";

import AuthService from "services/auth";

export default {
  props: {
    templateId: {
      type: String,
      default: null,
    },
    collectionId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      errorMessage: "",
      googleSignInParams: {
        client_id: "319571733082-hve1nke2vtlkbgi197pk7s6l3rqprpfk.apps.googleusercontent.com",
      },
      signupForm: {
        name: "",
        email: "",
        password: "",
        inviteToken: this.inviteToken,
      },
    };
  },

  computed: {
    ...mapState({
      routeAfterLogin: (state) => state.routeAfterLogin,
    }),
    ...mapState(["user"]),
    successUrl() {
      let templateId = this.templateId;
      let collectionId = this.collectionId;

      let destination = { name: "Dashboard" };

      if (templateId) {
        destination = { name: "Template", params: { templateId } };
      } else if (collectionId) {
        destination = { name: "Collection", params: { collectionId } };
      } else if (this.$route.query.next) {
        destination = { path: this.$route.query.next };
      }
      if (this.user) {
        if (this.user.is_writer == null ) {
          if (templateId) {
            destination = { name: "ViewingModeWithTemplate", params: { templateId } };
          } else if (collectionId) {
            destination = { name: "ViewingModeWithCollection", params: { collectionId } };
          } else {
            destination = { name: "LoginSelectViewingMode" }
          };
        };
      };
      let { href } = this.$router.resolve(destination);

      return href;
    },
    umLoginUrl() {
      return AuthService.backendLoginUrl("umich-oidc", { destination: this.successUrl });
    },
  },

  methods: {
    ...mapActions(["login", "setRouteAfterLogin"]),

    googleSigninSuccess(googleUser) {
      const id_token = googleUser.getAuthResponse().id_token;
      AuthService.googleLogin({
        id_token: id_token,
      })
        .then((response) => {
          let result = response.data;
          if (result.success) {
            this.login({
              token: result.data.token,
              refresh: result.data.refresh,
              user: result.data.user,
            });
            this.$router.push(this.successUrl);
          } else {
            this.errorMessage = result.error;
          }
        })
        .catch((errorResponse) => {
          this.errorMessage = errorResponse.data.error;
        });
    },

    googleSigninError(error) {
      console.error(error);
    },

    submitSignup() {
      this.errorMessage = "";

      if (this.signupForm.inviteToken) {
        AuthService.register(this.signupForm)
          .then((response) => {
            let result = response.data;
            if (result.success) {
              this.login({
                token: result.token,
                refresh: result.refresh,
                user: result.user,
              });
              if (this.routeAfterLogin && !this.$route.query.next) {
                router.push(this.routeAfterLogin);
              } else {
                this.$router.push(this.successUrl);
              }
              this.setRouteAfterLogin(null);
            } else {
              this.errorMessage = result.error;
            }
          })
          .catch((errorResponse) => {
            if (this.inviteToken && errorResponse.data.inviteToken) {
              this.errorMessage =
                "This invitation code is invalid or has expired. Check the provided email address.";
              return;
            }
            this.errorMessage = JSON.stringify(Object.values(errorResponse.data.error));
            this.errorMessage = this.errorMessage.replace(/[^\w\s]/gi, "");
          });
      } else {
        AuthService.registerEmail(this.signupForm)
          .then(() => {
            this.$router.push({ name: "SignupCheckEmail" });
          })
          .catch((response) => {
            this.errorMessage = response.data.email.toString();
          });
      }
    },
  },

  beforeCreate() {
    this.inviteToken = this.$route.query.inviteToken;
  },

  validations: {
    templateId: {
      required,
    },

    signupForm: {
      name: {
        required,
      },

      email: {
        required,
        email,
      },

      password: {
        required,
      },
    },
  },
};
</script>
