<template>
  <div class="container external-page layout-not-logged-in">
    <et-header class="external" />
    <div class="container">
      <div class="narrow-container">
        <h1 class="serif text-large text-highlight-primary">Enter new password</h1>

        <input
          type="password"
          class="input text-small"
          placeholder="Password"
          v-model="password"
          @input="$v.password.$touch"
        />
        <input
          type="password"
          class="input text-small"
          placeholder="Confirm password"
          v-model="passwordConfirmation"
          @input="$v.passwordConfirmation.$touch"
        />

        <template v-if="$v.password.$dirty">
          <p v-if="!$v.password.required" class="error-text-lighter text-small" aria-live="polite">
            Password is required.
          </p>
        </template>
        <template v-if="$v.passwordConfirmation.$dirty">
          <p v-if="!$v.passwordConfirmation.required" class="error-text-lighter text-small" aria-live="polite">
            Password confirmation is required
          </p>
          <p
            v-else-if="!$v.passwordConfirmation.sameAsPassword"
            class="error-text-lighter text-small"
            aria-live="polite"
          >
            Password must match.
          </p>
        </template>

        <button class="button" :disabled="!buttonEnabled" @click="submitReset">Save</button>
      </div>
    </div>
  </div>
</template>

<script>
import { required, sameAs } from "vuelidate/lib/validators";

import AuthService from "services/auth";

export default {
  name: "LoginResetPassword",

  props: {
    token: {
      type: String,
      default: "",
    },
    email: {
      type: String,
      default: "",
    },
  },

  data: () => ({
    error: null,
    success: false,
    password: null,
    passwordConfirmation: null,
  }),

  computed: {
    buttonEnabled() {
      // Enable button only if input is dirty and there are no errors
      return this.$v.$dirty && !this.$v.$error;
    },
  },

  methods: {
    submitReset() {
      AuthService.reset({
        password: this.password,
        password_confirmation: this.passwordConfirmation,
        token: this.token,
        email: this.email,
      })
        .then(() => {
          this.$router.push({
            name: "Login",
          });
        })
        .catch((err) => {
          this.error = err.data.error;
        });
    },
  },

  validations: {
    password: {
      required,
    },
    passwordConfirmation: {
      required,
      sameAsPassword: sameAs("password"),
    },
  },
};
</script>

<style scoped></style>
