<template>
  <main id="main" class="container-min flex column admin-wrapper">
    <div class="breadcrumb-navigation">
      <router-link class="button-link" :to="{ name: 'Dashboard' }">Dashboard</router-link>
      <img alt="" src="/static/images/icons/arrow-dark-blue.png" />
      <router-link class="button-link" :to="{ name: 'AdminHome' }">Site Administration</router-link>
      <img alt="" src="/static/images/icons/arrow-dark-blue.png" />
      <router-link class="button-link" :to="{ name: 'LtiDeployments' }">Deployments</router-link>
      <img alt="" src="/static/images/icons/arrow-dark-blue.png" />
      <p class="current-location">Add Deployment</p>
    </div>
    <div class="admin-header">
      <h1 class="title serif">Add Deployment</h1>
    </div>
    <div class="admin-content">
      <div class="admin-item details-container">
        <header>
          <div class="header-container">
            <img alt="" src="/static/images/icons/lti-registration.svg" />
            <h2 class="bold text-med">Add Deployment</h2>
          </div>
        </header>
        <div class="card-container not-table">
          <form>
            <div class="form-field">
              <label class="form-label bold" for="name">Deployment ID:</label>
              <input
                class="input"
                id="name"
                required
                v-model="deploymentId"
                v-bind="errorAttributes('deployment_id')"
              />
              <p class="error-text" id="deployment_id_error" v-if="errors.deployment_id" aria-live="polite">
                {{ errors.deployment_id }}
              </p>
            </div>

            <div class="form-field">
              <label class="active-checkbox">
                <input id="checkbox" type="checkbox" v-model="isActive" />
                Is Active
              </label>
            </div>

            <div class="buttons">
              <button class="button" type="button" @click="addDeployment">
                Save Deployment
              </button>
              <router-link class="button-secondary" type="button" :to="{ name: 'LtiDeployments' }">
                Cancel
              </router-link>
            </div>
          </form>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { defineComponent, ref, reactive } from "vue";
import { useRoute, useRouter, useStore } from "shared/composables";

export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const registrationId = parseInt(route.params.registrationId);

    const deploymentId = ref("");
    const isActive = ref(true);

    const errors = reactive({ deployment_id: "", is_active: "" });

    const addDeployment = async () => {
      const deployment = {
        registration: registrationId,
        deployment_id: deploymentId.value,
        is_active: isActive.value,
      };
      try {
        await store.dispatch("admin/createDeployment", { deployment });
        store.dispatch(
          "showSnackbar", `Deployment ${deployment.deployment_id} has been created.`
        );
        router.push({ name: 'LtiDeployments', params: { registrationId }});
      } catch (error) {
        for (const key in errors) {
          errors[key] = error.data[key] ? error.data[key].join(" ") : "";
        }
        store.dispatch("showSnackbar", "Please correct your errors.");
      }
    }

    const errorAttributes = (key) => {
      if (!errors[key]) {
        return {};
      }
      return {
        "aria-invalid": "true",
        "aria-describedby": key + "_error",
      };
    }

    return {
      deploymentId,
      isActive,
      errors,
      errorAttributes,
      addDeployment,
    };
  },
});
</script>
