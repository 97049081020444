<template>
  <ls-modal
    @close="$emit('close')"
    :visible="true"
    :ex-large="true"
    class="public-templates-modal-details example-details draft-checklist">
    <template #content>
      <div v-if="examples.length > 0" style="height: 100%;">
        <h2 id="dialog_label" class="text-med">{{ template.name }} Details</h2>
        <div class="modal-body public-templates-modal example-detail checklist-panel">
          <div class="draft-checklist left">
            <h3 class="example-detail-subheader">Checklist Items</h3>
            <example-tag-checklist
              :tags="tags"
              :example-tags="currentExample.tags"
              :highlight="highlight"
              @highlight="updateHighlight"
            />
          </div>
          <div class="right">
            <div class="header-container">
              <h3 class="example-detail-subheader">Examples</h3>
              <div class="button-container" v-if="examples.length > 0">
                <button
                  aria-label="Browse Left"
                  class="rotated button"
                  :disabled="examples.length == 1"
                  @click="rotate(-1)"
                >
                  <img alt class="icon arrow" src="/static/images/icons/arrow-white.png" />
                </button>
                <span class="example-numbers">{{ currentIndex + 1 }}/{{ examples.length }}</span>
                <button
                  aria-label="Browse Right"
                  class="right button"
                  :disabled="examples.length == 1"
                  @click="rotate(1)"
                >
                  <img alt class="icon arrow" src="/static/images/icons/arrow-white.png" />
                </button>
              </div>
            </div>
            <example-display
              :example="currentExample"
              :highlight="highlight"
              @focus="clearHighlight"
              @blur="clearHighlight"
            />
          </div>
        </div>
      </div>
      <div class="empty-state" v-else>
        <img alt="" class="empty-image" src="/static/images/empty-states/empty-examples.png" />
        <p class="text-med serif">There are no examples to share!</p>
        <p>
          The owners of this template have yet to approve any examples. Compose a draft and be the
          first to submit one!
        </p>
      </div>
    </template>
  </ls-modal>
</template>

<script>
import Modal from "shared/components/Modal.vue";
import ExampleDisplay from "../../../modules/template/components/ExampleDisplay.vue";
import ExampleTagChecklist from "../../../modules/template/components/ExampleTagChecklist.vue";
import { mapState } from "vuex";

export default {
  components: {
    "ls-modal": Modal,
    ExampleDisplay,
    ExampleTagChecklist,
  },
  props: {
    template: {
      type: Object,
      required: true,
    },
    examples: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      highlight: null,
      currentIndex: 0,
    };
  },
  watch: {
    examples(examples) {
      this.currentIndex = Math.max(0, Math.min(examples.length - 1, this.currentIndex));
    },
  },
  computed: {
    ...mapState("template", {
      allTemplates: "allTemplates",
      allChecklistItems: "allChecklistItems",
    }),
    tags() {
      let template = this.template;
      let allChecklistItems = this.allChecklistItems;
      return allChecklistItems.filter((ci) => ci.template_id == template.id);
    },
    currentExample() {
      let examples = this.examples;
      let currentIndex = this.currentIndex;

      if (examples) {
        return examples[currentIndex];
      }
      return null;
    },
  },
  methods: {
    rotate(step) {
      let currentIndex = this.currentIndex + step + this.examples.length;
      this.currentIndex = currentIndex % this.examples.length;
    },
    updateHighlight(newHighlight) {
      if (this.highlight && this.highlight == newHighlight) {
        this.highlight = null;
      } else {
        this.highlight = newHighlight;
      }
    },
    clearHighlight() {
      this.highlight = null;
    },
  },
};
</script>
