<template>
  <ls-modal @close="$emit('close')" :visible="true">
    <template #content>
      <h2 id="dialog_label" class="text-med">Clone</h2>
      <div class="modal-body public-templates-modal">
        <p>
          You are about to clone the <b>"{{ template.name }}"</b> template and become an owner of
          the duplicate. This duplicate will include the template’s current checklist and examples.
          Current members, drafts, and submissions will not be carried over. The duplicate template
          will appear in your dashboard for you to edit and customize however you’d like. Continue?
        </p>
      </div>
    </template>
    <template #footer>
      <div class="buttons">
        <button class="button-secondary" @click="$emit('close')">Cancel</button>
        <button class="button" @click="cloneTemplate">Continue</button>
      </div>
    </template>
  </ls-modal>
</template>
  <script>
import Modal from "shared/components/Modal.vue";
import TemplateService from "services/template";
import { mapActions, mapState, mapMutations } from "vuex";
import { SET_ALL_TEMPLATES } from "../../../modules/template/store/mutations";

export default {
  components: {
    "ls-modal": Modal,
  },
  props: {
    template: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      retrieving: false,
      id: '',
    };
  },
  computed: {
    ...mapState("template", { allTemplates: "allTemplates" }),
  },
  methods: {
    ...mapActions("template", {
      getAllTemplateChecklistGroups: "getAllTemplateChecklistGroups",
      getAllTemplateChecklistItems: "getAllTemplateChecklistItems",
      getTemplates: "getTemplates",
    }),
    ...mapMutations("template", {
      setTemplates: SET_ALL_TEMPLATES,
    }),
    cloneTemplate() {
      this.retrieving = true;
      TemplateService.clone(this.template.id)
        .then((response) => {
          if (response.status == 200) {
            const allTemplates = this.allTemplates;
            const newTemplate = response.data;
            if (!this.allTemplates.some((t) => t.id === newTemplate.id)) {
              this.setTemplates([...allTemplates, newTemplate]);
            }
            this.getAllTemplateChecklistGroups();
            this.getAllTemplateChecklistItems();
            this.getTemplates();
            this.$emit("cloned-template", newTemplate);
          }
        })
        .finally(() => {
          this.retrieving = false;
        });
    },
  }
};
</script>