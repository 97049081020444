<template>
  <modal :ex-large="true" :visible="visible" @close="close" class="anonymize-email-modal">
    <template #content>
      <div class="submit-example-modal-container">
        <div v-if="email && !showSuccessMessage">
          <h1 class="text-med">Submit as example</h1>
          <p class="subject">
            If approved, your draft will be shared as an example for other people using this
            template. Anonymize names as neccessary - these changes will not affect your original
            draft.
          </p>

          <div class="modal-form-field">
            <label for="subject" class="form-label bold">Subject</label>
            <input
              id="subject"
              ref="subject"
              class="input black-text"
              :value="email.subject"
              readonly
              @select="selectSubject"
            />
          </div>

          <div class="modal-form-field">
            <label for="draft-content" class="form-label bold">Draft</label>
            <email-content
              id="draft-content"
              ref="content"
              :email="email"
              :hideSubject="true"
              class="input draft-content"
              @select="selectContent"
              @text-change="updateEmailContent"
            ></email-content>
          </div>
        </div>
        <div v-if="showSuccessMessage">
          <h2 class="text-med">Submission Successful</h2>
          <div>
            Your {{ template.name }} draft has been submitted for the template owner to review.
            Thank you!
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div>
        <div class="buttons" v-if="!showSuccessMessage">
          <button class="button-secondary" @click="anonymize">Anonymize Selection</button>
          <button class="button" @click="prepareToSave">Submit Example</button>
          <button v-if="isModified" class="button-warning" @click="revertModification">
            Revert
          </button>
        </div>
        <p v-if="!showSuccessMessage" class="error-text text-center" aria-live="polite">
          If approved, your draft will be visible to others working on the same template
        </p>
        <div class="flex vc sb" v-if="showSuccessMessage">
          <button class="button full primary fill" @click="saveEmailExample">Close</button>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { mapState } from "vuex";

import EmailContent from "shared/components/email/EmailContent.vue";
import Modal from "shared/components/Modal.vue";

import ExamplesService from "services/examples";

export default {
  name: "AnonymizeEmailModal",
  components: {
    Modal,
    EmailContent,
  },

  props: {
    originalEmail: {
      type: Object,
      default: null,
    },

    visible: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      email: null,
      isModified: false,
      isSelected: false,
      isContentSelected: false,
      replacements: [],
      selectedRange: {
        index: 0,
        length: 0,
      },
      showSuccessMessage: false,
      finalTags: [],
    };
  },

  computed: {
    ...mapState({
      template: (state) => state.template.currentTemplate,
    }),

    emailContent() {
      return this.$refs.content;
    },

    subject() {
      return this.$refs.subject;
    },
  },

  created() {
    this.email = Object.assign({}, this.originalEmail);
  },

  watch: {
    originalEmail(newValue) {
      this.email = Object.assign({}, newValue);
    },
  },

  methods: {
    anonymize() {
      if (this.isSelected) {
        if (this.isContentSelected) {
          this.replace(this.selectedRange, "[Anonymized]");
          this.replacements.push(this.selectedRange);
        } else {
          this.replace(this.selectedRange, "[Anonymized]");
        }
        this.isSelected = false;
        this.isModified = true;
        this.emailContent.blur();
      }
    },

    close() {
      if (this.showSuccessMessage) {
        this.saveEmailExample();
      }
      this.$emit("close");
    },

    prepareToSave() {
      this.showSuccessMessage = true;
      this.finalTags = this.emailContent.editor.tagList.tags;
    },

    replace(range, text) {
      if (this.isContentSelected) {
        this.emailContent.replaceContent(range, text);
      } else {
        const end = this.selectedRange.index + this.selectedRange.length;
        this.email.subject =
          this.email.subject.substring(0, this.selectedRange.index) +
          text +
          this.email.subject.substring(end);
      }
    },

    updateEmailContent({ html }) {
      this.email.content = html;
    },

    revertModification() {
      this.email = Object.assign({}, this.originalEmail);
      this.isModified = false;
    },

    selectContent(selectedRange) {
      this.isSelected = true;
      this.isContentSelected = true;
      this.selectedRange = selectedRange;
    },

    selectSubject() {
      if (this.subject.selectionEnd - this.subject.selectionStart > 0) {
        this.isSelected = true;
        this.isContentSelected = false;
        this.selectedRange.index = this.subject.selectionStart;
        this.selectedRange.length = this.subject.selectionEnd - this.subject.selectionStart;
      }
    },

    saveEmailExample() {
      const examplePayload = {
        email_id: this.email.id,
        content: this.email.content,
        subject: this.email.subject,
        tags: this.finalTags,
      };

      ExamplesService.create(examplePayload).then(() => {
        this.$emit("completed");
      });
    },
  },
};
</script>
